<template>
  <div>
    <div>
      <layout-breadcrumb :list="[{'name':'班级列表'},{'name':commonClass.name+'识别列表'}]"></layout-breadcrumb>
    </div>
    <div>
      <Card>
        <p slot="title" style="height: 28px;line-height: 28px;">
          <Tag color="blue" v-if="commonClass.name"><h2>{{commonClass.name}}</h2></Tag> 识别列表
        </p>
        <div>
          <div style="display: flex;justify-content: space-between">
            <div>
              <Form ref="formInline" :model="queryForm" :inline="true" :label-width="100">
                <FormItem label="扫描起止时间">
                  <DatePicker v-model="queryForm.time" type="datetimerange" placeholder="请选择扫描起止时间"
                              style="width: 320px"></DatePicker>
                </FormItem>
                <FormItem>
                  <Button type="primary" @click="queryPageList" icon="ios-search">查询</Button>
                </FormItem>
              </Form>
            </div>
            <div>
              <pushwork></pushwork>
            </div>
          </div>
          <div>
            <div>
              <Table ref="table" :loading="tableLoading" :columns="columns" :data="pageList" border>
                <template slot-scope="{ row }" slot="action">
                  <Button type="primary" size="small" @click="catDetail(row)">查看</Button>
                </template>
              </Table>
            </div>
            <div style="display: flex;justify-content: center;margin-top: 20px">
              <Page :total="pageData.total" :page-size="pageData.pageSize" @on-change="changePageNo"/>
            </div>
          </div>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import pushwork from '@/views/modules/pushwork/index';

export default {
  name: "teacher-homework",
  components: {pushwork},
  data() {
    return {
      queryForm: {
        classId:'',
        time: []
      },
      tableLoading: true,
      pageList: [],
      classList: [],
      pageData: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      columns: [
        {
          title: '作业编号',
          key: 'number'
        },
        {
          title: '班级',
          key: 'className'
        },
        {
          title: '届号',
          key: 'enterYear'
        },
        {
          title: '扫描时间',
          key: 'createInDate'
        },
        {
          title: '操作',
          slot: 'action',
          fixed: 'right',
          width: 100,
        }
      ],
      commonClass:{
        id: 0,
        name: ''
      }
    }
  },
  created() {
    this.getInfo()
  },
  mounted() {
    this.queryPageList();
    this.queryClassStudent();
  },
  methods: {
    getInfo(){
      let data = this.$routeQuery()
      if(data.enterYear){
        data.name = data.enterYear+'届'+data.name
      }else{
        data.name =''
      }
      this.commonClass = data
    },
    queryClassStudent() {
      this.$http.queryClassStudent().then(res => {
        if (res.status == 1) {
          this.classList = res.data.map(item => {
            return item.classInfo;
          });
        }
      })
    },
    queryPageList() {
      this.$http.queryHomeworkList(
          this.pageData.pageNo,
          this.pageData.pageSize,
          this.commonClass.id,
          this.queryForm.time[0] ? this.$utils.getTimeStr(this.queryForm.time[0]).substring(0, 19) : '',
          this.queryForm.time[1] ? this.$utils.getTimeStr(this.queryForm.time[1]).substring(0, 19) : '',
      ).then(res => {
        this.tableLoading = false;
        if (res.status == 1) {
          this.pageList = res.data.records;
          this.pageData.total = res.data.total;
        }
      });
    },
    changePageNo(val) {
      this.pageData.pageNo = val;
      this.queryPageList();
    },
    catDetail(row) {
      let query = {homeworkNumber: row.number, classId: this.commonClass.id, name: this.commonClass.name}
      this.$jump('/detail-list', query);
    }
  }
}
</script>

<style scoped>

</style>